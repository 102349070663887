/* eslint-disable no-unused-vars */
import { memo, useEffect, useMemo, useState, useReducer } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import cn from 'classnames'
// Components
import SubMenuBanner from './libs/subMenuBanner/SubMenuBanner'
// utils
import { CUSTOMER_UNREAD_NOTIFICATIONS } from '@/graphql/index'
import { useDiscountContext } from '../../context/discountContext'
import useMediaQuery from '@/utils/publicApi'
import { getToken, getUserData } from '@/utils/tokenStorage'
import styles from './Header.module.scss'

import {
  SectionProps,
  DropDownProps,
  HeaderProps,
  UnreadNotifications,
} from './types'

const DynamicMenu = dynamic(() => import('./Menu'))
const DiscountBanner = dynamic(() => import('@/components/discountBanner/'))
const DynamicButton = dynamic(() => import('@/components/newButton/Button'))
const DynamicEditPasswordModal = dynamic(
  () => import('@/components/editPasswordModal'),
)
const ModalVets = dynamic(() => import('./ModalVets'))
const ModalPuppies = dynamic(() => import('./ModalPuppies'))
const ModalAboutUs = dynamic(() => import('./ModalAboutUs'))
const Modal = dynamic(() => import('./ButtonModal'))

const Section = memo(({ title, href = '/', path }: SectionProps) => (
  <Link href={href}>
    <div className={styles.section}>
      <div className={styles.title}>{title}</div>
      {path === href && <div className={styles.bar} />}
      {path && <div className={styles.bar2} />}
    </div>
  </Link>
))

const conditionPathUS = [
  '/puppy-travel',
  '/mission',
  '/reviews',
  '/faq/customer',
  '/referral',
  '/contact',
  '/about-mawoo-pets',
  '/why-mawoo-pets',
  '/insurance',
]

const discountBannerCondition = ['/search', '/puppy']

const DropDowMenuAU = memo(({ title, path }: DropDownProps) => (
  <div className={styles.sectionAU}>
    <div className={styles.titleAU}>{title}</div>

    {conditionPathUS.some((elem) => elem === path) && (
      <div className={styles.barAU} />
    )}
    {path && <div className={styles.lineAUHover} />}
  </div>
))

const DropDowMenuVets = memo(({ title, path }: DropDownProps) => (
  <div className={styles.sectionVets}>
    <div className={styles.titleVets}>{title}</div>
    {path.includes('/find-a-vet') && <div className={styles.barVets} />}
    {path && <div className={styles.lineVetsHover} />}
  </div>
))

const DropDowMenu = memo(({ title, path }: DropDownProps) => (
  <div className={styles.section}>
    <div className={styles.title}>{title}</div>
    {path && <div className={styles.lineHover} />}
  </div>
))

const Icon = memo(({ href = '/', type }: { href: string; type: string }) => {
  let children
  switch (type) {
    case 'fav':
      children = <div className={styles.fav} />
      break
    case 'appNot':
      children = <div className={styles.appNot} />
      break
    case 'application':
      children = <div className={styles.application} />
      break
    case 'pet':
      children = <div className={styles.pet} />
      break
    default:
      children = null
  }

  return <Link href={href}>{children}</Link>
})

type DataLayer = {
  push: (event: { event: string }) => void
}

export default function Header({
  isDenied,
  disabled,
  isDiscountBannerClosed,
  setIsDiscountBannerClosed,
}: HeaderProps) {
  const router = useRouter()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    // mismatch between server and client rendering fix
    setIsMounted(true)
  }, [])

  const initialState = {
    showMenu: false,
    showLoginModal: false,
    showSignUpModal: false,
    showAboutUsModal: false,
    showPuppiesModal: false,
    showVetsModal: false,
  }

  function reducer(state, action) {
    switch (action.type) {
      case 'set':
        return {
          ...state,
          [action.name]: action.value,
        }
      default:
        throw new Error()
    }
  }

  const isOpen = true
  const [state, dispatch] = useReducer(reducer, initialState)
  const { discount, endDate, now } = useDiscountContext()

  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [hasNotifications, setHasNotifications] =
    useState<UnreadNotifications>()

  // Get user data
  const [userData, setUserData] = useState(null)
  useEffect(() => {
    const _userData = getUserData(getToken())
    if (_userData) setUserData(_userData)
  }, [])

  useEffect(() => {
    if (userData) fetchNotifications()
  }, [userData])

  const fetchNotifications = async () => {
    const apolloClient = await (
      await import('@/utils/apolloClient')
    ).initializeApollo()
    const { data } = await apolloClient.query({
      query: CUSTOMER_UNREAD_NOTIFICATIONS,
    })
    setHasNotifications(data.unreadNotifications)
  }

  // Get dog applications
  let unreadApplications
  if (userData?.type === 'Customer') {
    unreadApplications = !!(hasNotifications as UnreadNotifications)?.count
  }

  // Auto-collapse menu
  const isTablet = useMediaQuery('(max-width:1270px)')
  useEffect(() => {
    if (!isTablet && !userData)
      dispatch({ type: 'set', name: 'showMenu', value: false })
  }, [isTablet])

  // show number in Mobile/Tablet
  const showNumberOnTablet = useMemo(() => {
    return (
      isTablet && (
        <>
          <a href={`${process.env.NEXT_PUBLIC_ROOT_URL}/search`}>
            <img
              src="/header/search.svg"
              alt="magnifying glass"
              height={25}
              width={25}
            />{' '}
          </a>
          <a
            href="tel:+1-888-488-7203"
            onClick={() => {
              // GTM call
              const { dataLayer } = window as unknown as {
                dataLayer?: DataLayer
              }
              if (dataLayer) {
                dataLayer.push({ event: 'call' })
              }
            }}
          >
            <img
              src="/header/phone-green.svg"
              alt="green phone"
              style={{ width: '20px', height: '20px' }}
            />
          </a>
        </>
      )
    )
  }, [isTablet])

  const hasBanner = useMemo(
    () =>
      discountBannerCondition.some(
        (cond) => router.pathname.includes(cond) || router.pathname === '/',
      ) &&
      !(now > endDate) &&
      !isDiscountBannerClosed &&
      !!discount?.amount &&
      discount?.bannerText !== '<p><br></p>' &&
      discount?.bannerText !== null &&
      !discount?.customerSpecific,
    [
      discountBannerCondition,
      router.pathname,
      isDiscountBannerClosed,
      discount,
    ],
  )
  if (disabled) return null

  // Define sections and keypad content
  const section = (
    <>
      <div
        className={styles.buttons}
        onMouseEnter={() =>
          dispatch({ type: 'set', name: 'showPuppiesModal', value: true })
        }
        onMouseLeave={() =>
          dispatch({ type: 'set', name: 'showPuppiesModal', value: false })
        }
        onClick={() =>
          dispatch({ type: 'set', name: 'showPuppiesModal', value: true })
        }
      >
        <div className={styles.buttonContainer2}>
          <div className={styles.puppiesOption}>
            <DropDowMenu title="Puppies" path={router.pathname} />
            {state.showPuppiesModal && <div className={styles.line} />}
          </div>
          {state.showPuppiesModal && (
            <ModalPuppies
              onOutsideClick={() =>
                dispatch({
                  type: 'set',
                  name: 'showPuppiesModal',
                  value: false,
                })
              }
            />
          )}
        </div>
      </div>
      <Section
        title="Puppy Match Quiz"
        href="/dog-breed-quiz"
        path={router.pathname}
      />
      <div
        className={styles.buttons}
        onMouseEnter={() =>
          dispatch({ type: 'set', name: 'showVetsModal', value: true })
        }
        onMouseLeave={() =>
          dispatch({ type: 'set', name: 'showVetsModal', value: false })
        }
        onClick={() =>
          dispatch({ type: 'set', name: 'showVetsModal', value: true })
        }
      >
        <div className={styles.buttonContainer2}>
          <div className={styles.btnVets}>
            <DropDowMenuVets title="Find a Vet" path={router.pathname} />
            {state.showVetsModal && <div className={styles.lineVets} />}
          </div>
          {state.showVetsModal && (
            <ModalVets
              onOutsideClick={() =>
                dispatch({ type: 'set', name: 'showVetsModal', value: false })
              }
            />
          )}
        </div>
      </div>
      <div
        className={styles.buttons}
        onMouseEnter={() =>
          dispatch({ type: 'set', name: 'showAboutUsModal', value: true })
        }
        onMouseLeave={() =>
          dispatch({ type: 'set', name: 'showAboutUsModal', value: false })
        }
        onClick={() =>
          dispatch({ type: 'set', name: 'showAboutUsModal', value: true })
        }
      >
        <div className={styles.buttonContainer2}>
          <div className={styles.btnAboutUs}>
            <DropDowMenuAU title="About Us" path={router.pathname} />
            {state.showAboutUsModal && <div className={styles.lineAU} />}
          </div>

          {state.showAboutUsModal && (
            <ModalAboutUs
              userData={userData}
              onOutsideClick={() =>
                dispatch({
                  type: 'set',
                  name: 'showAboutUsModal',
                  value: false,
                })
              }
            />
          )}
        </div>
      </div>

      <Section title="Blog" href="/blog" path={router.pathname} />
    </>
  )
  let sections
  let keypadContent

  switch (userData?.type) {
    case 'Customer':
      sections = section
      keypadContent = (
        <>
          <Icon href="/customer/favourites" type="fav" />
          {unreadApplications ? (
            <Icon href="/customer/applications" type="appNot" />
          ) : (
            <Icon href="/customer/applications" type="application" />
          )}
        </>
      )
      break

    case 'Breeder':
      sections = section
      keypadContent = (
        <>
          <Icon href="/breeder/pets" type="pet" />
          <Icon href="/breeder/applications/requests" type="application" />
        </>
      )
      break

    default:
      sections = <>{section}</>
      keypadContent = null
      break
  }
  return (
    <>
      <div
        id="navbar"
        className={cn({
          [styles.container]: true,
          [styles.hasBanner]: hasBanner || false,
        })}
      >
        {/* password modal */}
        {!showPasswordModal && (
          <DynamicEditPasswordModal
            isOpen={showPasswordModal}
            close={() => setShowPasswordModal(false)}
          />
        )}

        {/* discount banner */}
        {hasBanner && !state.showMenu && (
          <DiscountBanner
            isOpen={isOpen}
            isDiscountBannerClosed={isDiscountBannerClosed}
            setIsDiscountBannerClosed={setIsDiscountBannerClosed}
          />
        )}

        {/* header */}
        <div
          className={cn({
            [styles.header]: true,
            [styles.isBannerOpen]:
              isOpen && hasBanner && !state.showMenu && isMounted,
          })}
        >
          <div className={styles.sections1}>
            {(userData?.type === 'Customer' || !userData) && isMounted ? (
              <>
                <Link href="/">
                  <div
                    className={styles.mawooWithoutLogin}
                    role="img"
                    aria-label="Mawoo Pets horizontal logo"
                  />
                </Link>

                {isTablet && isMounted && (
                  <Link href="/puppy-financing" className={styles.financing}>
                    <img
                      src="/financings/wallet.svg"
                      alt="Wallet"
                      width={35}
                      height={35}
                    />
                    <span>
                      Buy Now
                      <br /> Pay Later
                    </span>
                  </Link>
                )}
              </>
            ) : (
              <Link href="/">
                <div
                  className={styles.mawoo}
                  role="img"
                  aria-label="Mawoo Pets"
                />
              </Link>
            )}

            {/* menu */}
            <div className={styles.options}>{sections}</div>
          </div>
          {/* buttons */}
          <div className={styles.sections2}>
            <div className={styles.actionIcons}>
              {(userData?.type === 'Customer' || !userData) &&
              !isTablet &&
              isMounted ? (
                <Link href="/puppy-financing" className={styles.financing}>
                  <img
                    src="/financings/wallet.svg"
                    alt="Wallet"
                    width={40}
                    height={40}
                  />
                  Buy Now Pay Later
                </Link>
              ) : null}
              {!isTablet && isMounted ? (
                <>
                  <Link
                    href={`${process.env.NEXT_PUBLIC_ROOT_URL}/search`}
                    className={styles.magnifying}
                  >
                    <img
                      src="/header/search.svg"
                      alt="magnifying glass"
                      height={25}
                      width={25}
                    />{' '}
                  </Link>
                  <a
                    className={styles.phone}
                    href="tel:+1-888-488-7203"
                    onClick={() => {
                      // GTM call
                      const { dataLayer } = window as unknown as {
                        dataLayer?: DataLayer
                      }
                      if (dataLayer) {
                        dataLayer.push({ event: 'call' })
                      }
                    }}
                  >
                    <img src="/header/phone-green.svg" alt="green phone" />
                  </a>
                </>
              ) : null}
            </div>

            <div className={styles.actions}>
              {userData && isMounted ? (
                <div className={styles.keypad}>
                  {keypadContent}

                  <div
                    className={styles.profile}
                    onClick={() =>
                      dispatch({
                        type: 'set',
                        name: 'showMenu',
                        value: !state.showMenu,
                      })
                    }
                  >
                    <div
                      className={cn(
                        styles.drop,
                        state.showMenu ? styles.up : styles.down,
                      )}
                    />
                    <div
                      className={styles.closeArea}
                      style={{ display: state.showMenu ? 'block' : 'none' }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.buttons}>
                  <div className={styles.buttonContainer}>
                    <DynamicButton
                      title="Login"
                      theme={['black-login', 'extra-short']}
                      onClick={() =>
                        dispatch({
                          type: 'set',
                          name: 'showLoginModal',
                          value: true,
                        })
                      }
                    />

                    {state.showLoginModal && (
                      <Modal
                        type="Login"
                        onOutsideClick={() =>
                          dispatch({
                            type: 'set',
                            name: 'showLoginModal',
                            value: false,
                          })
                        }
                      />
                    )}
                  </div>

                  <div className={styles.buttonContainer}>
                    <DynamicButton
                      title="Sign Up"
                      theme={['pink-solid', 'extra-short']}
                      onClick={() =>
                        dispatch({
                          type: 'set',
                          name: 'showSignUpModal',
                          value: true,
                        })
                      }
                    />

                    {state.showSignUpModal && (
                      <Modal
                        type="Sign Up"
                        onOutsideClick={() =>
                          dispatch({
                            type: 'set',
                            name: 'showSignUpModal',
                            value: false,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
              )}

              {isMounted && showNumberOnTablet}

              {/* burger menu */}
              <div
                onClick={() =>
                  dispatch({
                    type: 'set',
                    name: 'showMenu',
                    value: !state.showMenu,
                  })
                }
                className={cn(
                  styles.burger,
                  state.showMenu ? styles.close : styles.open,
                )}
              />

              {state.showMenu && (
                <DynamicMenu
                  userData={userData}
                  userType={userData?.type}
                  setUserData={setUserData}
                  dispatch={dispatch}
                  state={state}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <SubMenuBanner disabled={isDenied} userData={userData} />
    </>
  )
}
