import { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useMediaQuery from '../../../../utils/publicApi'
import styles from './subMenuBanner.module.scss'
import OutsideClickHandler from 'react-outside-click-handler'
import cn from 'classnames'

function Item({ onClick, type, href, isActive, disabled }) {
  let children
  switch (type) {
    case 'dashboard':
      children = <div className={styles.dashboard} />
      break
    case 'fav':
      children = <div className={styles.fav} />
      break
    case 'application':
      children = <div className={styles.application} />
      break
    case 'pet':
      children = <div className={styles.pet} />
      break
    case 'more':
      children = <div className={styles.more} />
      break
    case 'settings':
      children = <div className={styles.setting} />
      break
    default:
      children = null
  }

  if (!disabled && href) {
    return (
      <Link href={href} legacyBehavior>
        <div
          onClick={onClick}
          className={cn(styles.item, isActive ? styles.active : null)}
        >
          {children}
        </div>
      </Link>
    )
  } else {
    return (
      <div
        onClick={onClick}
        className={cn(styles.item, isActive ? styles.active : null)}
      >
        {children}
      </div>
    )
  }
}

// show sub menu in Mobile/Tablet for Breeder or Customer
export default function SubMenuBanner({ userData, disabled }) {
  const isTablet = useMediaQuery('(max-width:1270px)')
  const router = useRouter()
  const [showMoreModal, setShowMoreModal] = useState(false)
  const [scrollUp, setScrollUp] = useState(true)
  let scrollPos = 0

  // show banner bottom
  useEffect(() => {
    function handleScroll() {
      if (document.body.getBoundingClientRect().top > -300) setScrollUp(true)
      else if (document.body.getBoundingClientRect().top > scrollPos)
        setScrollUp(true)
      else {
        setScrollUp(false)
      }
      scrollPos = document.body.getBoundingClientRect().top
    }
    window.addEventListener('scroll', handleScroll)
    return window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    !scrollUp && setShowMoreModal(false)
  }, [scrollUp])

  return (
    isTablet && (
      <>
        {!userData ? null : (
          <>
            {userData?.type === 'Customer' && (
              <div
                className={cn({
                  [styles.banner]: true,
                  [styles.showBanner]: scrollUp,
                  [styles.hidden]: !scrollUp,
                })}
              >
                <Item
                  type="dashboard"
                  href="/customer/dashboard"
                  isActive={router.asPath.includes('/customer/dashboard')}
                />
                <Item
                  type="fav"
                  href="/customer/favourites"
                  isActive={router.asPath.includes('/customer/favourites')}
                />
                <Item
                  type="application"
                  href="/customer/applications"
                  isActive={router.asPath.includes('/customer/applications')}
                />
                <Item
                  type="settings"
                  href="/customer/profile"
                  isActive={router.asPath.includes('/customer/profile')}
                />
              </div>
            )}
            {userData?.type === 'Breeder' && (
              <>
                <div
                  className={cn({
                    [styles.banner]: true,
                    [styles.showBanner]: scrollUp,
                    [styles.hidden]: !scrollUp,
                  })}
                >
                  <Item
                    disabled={disabled}
                    type="dashboard"
                    href="/breeder/dashboard"
                    isActive={router.asPath.includes('/breeder/dashboard')}
                  />

                  <Item
                    disabled={disabled}
                    type="pet"
                    href="/breeder/pets"
                    isActive={router.asPath.includes('/breeder/pets')}
                  />
                  <Item
                    disabled={disabled}
                    type="application"
                    href="/breeder/applications/requests"
                    isActive={router.asPath.includes('/breeder/applications')}
                  />

                  <Item
                    type="more"
                    onClick={() => (!disabled ? setShowMoreModal(true) : null)}
                    isActive={
                      router.asPath.includes('breeder/earnings') ||
                      router.asPath.includes('/breeder/profile')
                    }
                  />
                </div>
                {showMoreModal && (
                  <div
                    className={cn({
                      [styles.modal]: true,
                      [styles.hidden]: !scrollUp,
                    })}
                  >
                    <div className={styles.arrow} />
                    <OutsideClickHandler
                      onOutsideClick={() => setShowMoreModal(false)}
                    >
                      <div className={styles.content}>
                        <Link href="/breeder/earnings" legacyBehavior>
                          <div className={styles.payouts}>
                            <img src="/header/bank.svg" alt="bank icon" />
                            Payouts
                          </div>
                        </Link>
                        <Link href="/breeder/profile" legacyBehavior>
                          <div className={styles.settings}>
                            <img
                              src="/header/settings.svg"
                              alt="settings icon"
                            />
                            Settings
                          </div>
                        </Link>
                      </div>
                    </OutsideClickHandler>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    )
  )
}
